import React, { useEffect } from "react";
import {
  LaptopOutlined,
  GlobalOutlined,
  NotificationOutlined,
  ShoppingOutlined,
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  HomeFilled,
  SettingOutlined,
  MailOutlined,
  DollarOutlined,
  HomeTwoTone,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import BrandingLogo from "../../components/BrandingLogo";
import { useDispatch } from "react-redux";
import { fetchNotifications } from "../../store/notificationReducer";
import "./styles/Sidebar.scss";

const { Content, Sider } = Layout;

const menuItems = [
  { label: "Dashboard", key: "/", icon: <HomeFilled /> },
  { label: "Orders", key: "/orders", icon: <ShoppingOutlined /> },
  { label: "Customers", key: "/customers", icon: <TeamOutlined /> },
  { label: "Websites", key: "/websites", icon: <GlobalOutlined /> },
  // { label: "Pricing", key: "/pricing", icon: <DollarOutlined /> },
  // { label: "Email", key: "/email", icon: <MailOutlined /> },
];

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);
const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [pathname]);

  return (
    <Sider width={160} className="dashboard-sidebar">
      {/* <div className="logo">
        <img src={logo}></img>
        <h1 className="logo-slogan"> Guest Posting Made Easy</h1>
      </div> */}

      <Menu
        mode="inline"
        className="menu"
        onClick={(option) => navigate(option.key)}
        selectedKeys={`/${pathname.split("/")[1]}`}
        items={menuItems}
      />
      {/* <div className="branding">
        <p>DIGITAL MARKETING PARTNER</p>
        <BrandingLogo />
      </div> */}
    </Sider>
  );
};

export default Sidebar;
