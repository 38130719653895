import ReactCountryFlag from "react-country-flag";
import { Tag, Popover, Descriptions, Space, Typography, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  FileOutlined,
  LinkOutlined,
  GlobalOutlined,
  StopOutlined,
  EditOutlined,
  CheckOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  ARTICLE_STATUS,
  DATE_FORMAT,
  ORDER_STATUS,
  USER_STATUS,
  WEBSITE_TOOLTIPS,
} from "./constants";
import {
  calculateYearsAndMonthsPassed,
  capitalizeFirstLetter,
  formatDate,
} from "./helper";
import WebsiteActions from "../dashboard/Websites/components/WebsiteActions";
import NewTabIcon from "../components/NewTabIcon";
import moment from "moment";
import WebsiteStatusActions from "../dashboard/OrderView/components/WebsiteStatus/WebsiteStatusActions";

export const WEBSITE_COLUMNS = [
  // {
  //   title: "ID",
  //   dataIndex: "_id",
  //   key: "_id",
  //   render: (url, record) => (
  //     <Link to={record._id}>
  //       <div className="website-url">
  //         <p>{url}</p>
  //         <EditOutlined />
  //       </div>
  //     </Link>
  //   ),
  // },
  {
    title: "Website",
    dataIndex: "url",
    key: "url",
    render: (url, record) => (
      <Link to={record._id}>
        <div className="website-url">
          <p>{url}</p>
          <EditOutlined />
        </div>
      </Link>
    ),
  },
  {
    title: "DA",
    key: "da",
    dataIndex: "da",
    className: "text-center",
  },
  {
    title: "DR",
    key: "dr",
    dataIndex: "dr",
    className: "text-center",
  },
  {
    title: "Traffic",
    key: "traffic",
    dataIndex: "traffic",
    className: "text-center",
  },
  {
    title: "Links",
    key: "links",
    dataIndex: "links",
    className: "text-center",
  },
  {
    title: "Country",
    key: "country",
    dataIndex: "country",
    className: "text-center",

    render: (country) =>
      country.code === "INT" ? (
        <GlobalOutlined className="flag" />
      ) : (
        <ReactCountryFlag className="flag" countryCode={country.code} svg />
      ),
  },
  {
    title: "Spam Score",
    key: "spam",
    dataIndex: "spam",
    className: "text-center",
  },
  {
    title: "Age",
    key: "age",
    dataIndex: "age",
    className: "text-center",
    render: (date) => calculateYearsAndMonthsPassed(date),
  },

  {
    title: "Categories",
    key: "categories",
    dataIndex: "categories",

    render: (categories) =>
      categories.map((c) => (
        <Tag className="category-tag" key={c.name}>
          <b>{c.name}(</b>
          <span className="cost"> ${c.cost}</span>
          <b> {"-> "}</b>
          <span className="price">${c.price}</span>
          <b> )</b>
        </Tag>
      )),
  },
  {
    title: "Vendor",
    key: "vendor",
    dataIndex: "vendor",
    className: "text-center",
  },
  // {
  //   title: "Cost",
  //   key: "cost",
  //   dataIndex: "cost",
  //   className: "text-center",

  //   render: (cost) => <span className="cost">${cost}</span>,
  // },
  // {
  //   title: "Retail Price",
  //   key: "price",
  //   dataIndex: "price",
  //   className: "text-center",
  //   render: (price) => <span className="price">${price}</span>,
  // },
  {
    title: "Last Updated",
    dataIndex: "last_updated",
    key: "last_updated",
    className: "text-center",
    render: formatDate,
    sorter: (a, b) => moment(a.last_updated).diff(moment(b.last_updated)),
  },
  {
    title: "Action",
    key: "action",

    className: "text-center",
    render: (record) => <WebsiteActions record={record} />,
  },
];

export const ORDERS_COLUMNS = [
  {
    title: "ORDER ID",
    dataIndex: "_id",
    key: "_id",
    render: (text) => (
      <Link className="order-link" to={text}>
        {text}
      </Link>
    ),
  },
  {
    title: "CUSTOMER EMAIL",
    dataIndex: "customer",
    key: "customer",
    render: (customer) => customer.email,
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
    render: (status) => (
      <Tag
        color={
          status === ORDER_STATUS.PENDING
            ? "blue"
            : status === ORDER_STATUS.IN_PROCESS
            ? "gold"
            : status === ORDER_STATUS.COMPLETED
            ? "green"
            : status === ORDER_STATUS.DRAFT
            ? "lime"
            : "red"
        }
      >
        {status.toUpperCase()}
      </Tag>
    ),
  },
  {
    title: "CREATED",
    dataIndex: "created_at",
    key: "created_at",
    render: formatDate,
    sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
  },
  {
    title: "DUE",
    dataIndex: "due_at",
    key: "due",
    render: (due, rec) =>
      rec.status === ORDER_STATUS.DRAFT ? "-" : <>{formatDate(due)}</>,
    sorter: (a, b) => moment(a.due_at).diff(moment(b.due_at)),
  },

  {
    title: "AMOUNT",
    dataIndex: "total_order_amount",
    key: "total_order_amount",
    render: (amount, rec) =>
      rec.status === ORDER_STATUS.DRAFT ? "-" : <a>${amount}</a>,
    sorter: (a, b) =>
      moment(a.total_order_amount).diff(moment(b.total_order_amount)),
  },
];

export const LINKS_COLUMN = [
  {
    title: "Link No",
    dataIndex: "link_no",
    key: "link_no",
  },
  {
    title: "Target Url",
    dataIndex: "target_url",
    key: "target_url",
    render: (value) => (value === "" ? "-" : value),
    editable: true,
  },
  {
    title: "Anchor Text",
    dataIndex: "anchor_text",
    render: (value) => (value === "" ? "-" : value),
    key: "anchor_text",
    editable: true,
  },
];

// export const ORDER_ITEMS_COLUMN = [
//   {
//     title: "ID",
//     dataIndex: "id",
//     key: "id",
//   },
//   {
//     title: "Website",
//     dataIndex: "website",
//     key: "website",
//     render: (url) => <span>{url}</span>,
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//     key: "status",
//     className: "text-center",

//     render: (status) => (
//       <Tag
//         color={
//           status === ARTICLE_STATUS.PENDING
//             ? "blue"
//             : status === ARTICLE_STATUS.IN_WRITING
//             ? "gold"
//             : status === ARTICLE_STATUS.SUBMITTED
//             ? "lime"
//             : status === ARTICLE_STATUS.PUBLISHED
//             ? "green"
//             : "red"
//         }
//       >
//         {status.toUpperCase()}
//       </Tag>
//     ),
//   },
//   {
//     title: "Links",
//     dataIndex: "links",
//     key: "links",
//     className: "text-center",
//     render: (links) => (
//       <div id="link-popover-container">
//         <Popover
//           overlayClassName="link-popover"
//           trigger="click"
//           placement="rightTop"
//           getPopupContainer={() =>
//             document.getElementById("link-popover-container")
//           }
//           content={
//             <Descriptions bordered size="small">
//               <Descriptions.Item
//                 className="header"
//                 label="Anchor Text"
//                 span={5}
//               >
//                 Target Url
//               </Descriptions.Item>
//               {links.map((e, index) => (
//                 <Descriptions.Item key={index} label={e.anchor_text} span={5}>
//                   <span className="link">{e.target_url}</span>
//                 </Descriptions.Item>
//               ))}
//             </Descriptions>
//           }
//         >
//           <LinkOutlined />
//         </Popover>
//       </div>
//     ),
//   },
//   {
//     title: "Category",
//     dataIndex: "category",
//     key: "category",
//     className: "text-center",
//     render: (category) => <>{capitalizeFirstLetter(category)}</>,
//   },
//   {
//     title: "Cost",
//     dataIndex: "cost",
//     key: "cost",
//     className: "text-center",
//     render: (cost) => <span className="cost">${cost}</span>,
//   },
//   {
//     title: "Price",
//     dataIndex: "price",
//     key: "price",
//     className: "text-center",
//     render: (price) => <span className="price">${price}</span>,
//   },
//   {
//     title: "Content",
//     dataIndex: "content_type",
//     key: "content_type",
//     className: "text-center",
//     render: (contentType) =>
//       contentType === "user_upload" ? <FileOutlined /> : "-",
//   },
//   {
//     title: "Delivery",
//     dataIndex: "delivery_type",
//     key: "delivery_type",
//     className: "text-center",
//     render: (delivery_type) =>
//       delivery_type === "Urgent" ? (
//         <p className="urgent">{delivery_type.toUpperCase()}</p>
//       ) : (
//         <p>{delivery_type.toUpperCase()}</p>
//       ),
//   },
//   {
//     title: "Published Url",
//     dataIndex: "published_url",
//     key: "published_url",
//     className: "text-center",
//     render: (url) =>
//       url ? (
//         <a href={url} target="_blank" className="result-link">
//           View Result
//         </a>
//       ) : (
//         "-"
//       ),
//   },
// ];

export const CUSTOMERS_COLUMNS = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
    render: (r, customer) => customer.first_name + " " + customer.last_name,
    sorter: (a, b) => a.first_name.localeCompare(b.first_name),
  },

  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Co.",
    key: "country",
    dataIndex: "country",
    className: "text-center",
    render: (country) =>
      country.code === "INT" ? (
        <GlobalOutlined className="flag" />
      ) : (
        <ReactCountryFlag className="flag" countryCode={country.code} svg />
      ),
  },
  {
    title: "Last Activity",
    dataIndex: "last_activity",
    key: "last_activity",
    render: formatDate,
    sorter: (a, b) => moment(a.last_activity).diff(moment(b.last_activity)),
  },
  {
    title: "Last Order",
    dataIndex: "last_order",
    key: "last_order",
    render: formatDate,
    sorter: (a, b) => moment(a.last_order).diff(moment(b.last_order)),
  },
  {
    title: (
      <div>
        <p>Total</p>
        <p>Orders</p>
      </div>
    ),
    dataIndex: "total_orders",
    key: "total_orders",
    className: "text-center",
    sorter: (a, b) => a.total_orders - b.total_orders,
  },

  {
    title: (
      <div>
        <p>Total</p>
        <p>Spending</p>
      </div>
    ),
    className: "text-center",
    dataIndex: "total_spending",
    key: "total_spending",
    render: (value) => <span>${value}</span>,
    sorter: (a, b) => a.total_spending - b.total_spending,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    className: "text-center",
    render: (status) =>
      status === USER_STATUS.ACTIVE ? (
        <CheckOutlined />
      ) : status === USER_STATUS.PENDING ? (
        <SyncOutlined />
      ) : (
        <StopOutlined />
      ),
  },
];

export const ORDER_ITEM_STATUS_COLUMN = [
  {
    title: "ID",
    dataIndex: "_id",
    key: "_id",
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
    render: (website) => <span>{website.url}</span>,
  },

  {
    title: "Links",
    dataIndex: "links",
    key: "links",
    className: "text-center",
    render: (links) => (
      <div id="link-popover-container">
        <Popover
          overlayClassName="link-popover"
          trigger="click"
          placement="rightTop"
          getPopupContainer={() =>
            document.getElementById("link-popover-container")
          }
          content={
            <Descriptions bordered size="small">
              <Descriptions.Item
                className="header"
                label="Anchor Text"
                span={5}
              >
                Target Url
              </Descriptions.Item>
              {links.map((e, index) => (
                <Descriptions.Item key={index} label={e.anchor_text} span={5}>
                  <span className="link">{e.target_url}</span>
                </Descriptions.Item>
              ))}
            </Descriptions>
          }
        >
          <LinkOutlined />
        </Popover>
      </div>
    ),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    className: "text-center",
    render: (category) => <>{capitalizeFirstLetter(category)}</>,
  },
  {
    title: "Cost",
    dataIndex: "cost_at_time_of_order",
    key: "cost_at_time_of_order",
    className: "text-center",
    render: (cost) => <span className="cost">${cost}</span>,
  },
  {
    title: "Price",
    dataIndex: "price_at_time_of_order",
    key: "price_at_time_of_order",
    className: "text-center",
    render: (price) => <span className="price">${price}</span>,
  },
  {
    title: "Vendor",
    dataIndex: "website",
    key: "website",
    className: "text-center",
    render: (website) => <span>{website.vendor}</span>,
  },
  {
    title: "Content Type",
    dataIndex: "content_type",
    key: "content_type",
    className: "text-center",
  },
  {
    title: "File",
    dataIndex: "file",
    key: "file",
    className: "text-center",
    render: (file) =>
      file ? (
        <Tooltip
          overlayClassName="table-tooltip-container"
          title="Click here to download the uploaded file"
          placement="right"
        >
          <a className="download-file" download={file.name} href={file.data}>
            <FileOutlined />
          </a>
        </Tooltip>
      ) : (
        "-"
      ),
  },
  {
    title: "Delivery",
    dataIndex: "delivery_type",
    key: "delivery_type",
    className: "text-center",
    render: (delivery_type) =>
      delivery_type === "Urgent" ? (
        <p className="urgent">{delivery_type.toUpperCase()}</p>
      ) : (
        <p>{delivery_type.toUpperCase()}</p>
      ),
  },
  {
    title: "Action",
    key: "action",
    className: "text-center",
    render: (record) => <WebsiteStatusActions orderItem={record} />,
  },
];
