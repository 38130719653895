import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findMinPrice, waitFunction } from "../utils/helper";
import { v4 as uuidv4 } from "uuid";
import {
  matchRange,
  matchCategory,
  matchString,
  searchString,
} from "../utils/validation";

import { useDispatch } from "react-redux";
import {
  CONTENT_TYPES,
  DELIVERY_TYPE,
  WEBSITE_FILTER,
  WEBSITE_TRAFFIC_OPTIONS,
} from "../utils/constants";
import { WEBSITES_LIST } from "../utils/samples/website";
import { revertAll } from "./action";
import authApi from "../services/authApi";
import handleApiResponse from "../components/ApiResponse";

const fetchWebsites = createAsyncThunk(
  "website/fetchWebsites",
  async (value, thunkAPI) => {
    try {
      const response = await authApi.get("/website");
      if (response.status === 200)
        return response.data.data.map((website) => ({
          ...website,
          price: findMinPrice(website.categories),
        }));
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const updateWebsites = createAsyncThunk(
  "website/updateWebsites",
  async (websitesList, thunkAPI) => {
    try {
      const response = await authApi.put("/website/update", websitesList);
      if (response.status === 200) return response;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const createWebsites = createAsyncThunk(
  "website/createWebsites",
  async (websitesList, thunkAPI) => {
    try {
      const response = await authApi.post("/website/create", websitesList);
      if (response.status === 200) return response;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const deleteWebsite = createAsyncThunk(
  "website/deleteWebsite",
  async (websiteId, thunkAPI) => {
    try {
      const response = await authApi.delete(`/website/delete/${websiteId}`);
      if (response.status === 200) return { response, websiteId };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const updateWebsiteStatus = createAsyncThunk(
  "website/updateWebsiteStatus",
  async ({ websiteId, status }, thunkAPI) => {
    try {
      const response = await authApi.put(
        `/website/status/${websiteId}/${status}`
      );
      if (response.status === 200) return { response, websiteId, status };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: "",
  cart: {},
  categories: [],
  extensions: [],
  articleEdit: null,
  countries: [],
  isApplyFilter: false,
  filterVisible: true,
  filters: WEBSITE_FILTER,
  filterResult: [],
};
export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      state.isApplyFilter = true;
      let websiteList = JSON.parse(JSON.stringify(state.data));
      const {
        price,
        da,
        category,
        country,
        search,
        traffic,
        extension,
        dr,
        spam,
      } = action.payload;

      websiteList = websiteList.filter(
        (website) =>
          matchRange(website.price, [price.min, price.max]) &&
          matchRange(website.da, da) &&
          matchRange(website.dr, dr) &&
          matchRange(website.spam, spam) &&
          matchRange(website.traffic, WEBSITE_TRAFFIC_OPTIONS[traffic]) &&
          matchCategory(website.categories, category) &&
          matchString(website.country.name, country) &&
          searchString(website.url, search) &&
          matchString(website.extension, extension)
      );
      state.filters = action.payload;
      state.filterResult = websiteList;
    },
    resetFilter: (state, action) => {
      state.filters = { ...WEBSITE_FILTER, search: state.filters.search };
      state.isApplyFilter = false;
    },
    setFilterVisible: (state, action) => {
      state.filterVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    //FETCH ALL WEBSITES LIST
    builder.addCase(fetchWebsites.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchWebsites.fulfilled, (state, action) => {
      //set the websites list into data
      state.data = action.payload;

      // get the unqiue categories from website list
      const uniqueCategories = new Set();
      const uniqueExtensions = new Set();
      action.payload.forEach((item) => {
        item.categories.forEach((category) => {
          uniqueCategories.add(category.name);
        });
        uniqueExtensions.add(item.extension);
      });
      const uniqueCategoriesArray = Array.from(uniqueCategories).sort();
      const uniqueExtensionsArray = Array.from(uniqueExtensions).sort();

      //get the unique countries in the website lists
      const uniqueCountries = {};
      action.payload.forEach((item) => {
        uniqueCountries[item.country.code] = item.country.name;
      });
      const sortedCountries = Object.fromEntries(
        Object.entries(uniqueCountries).sort((a, b) => a[0].localeCompare(b[0]))
      );

      state.categories = uniqueCategoriesArray;
      state.countries = sortedCountries;
      state.extensions = uniqueExtensionsArray;
      state.loading = false;
    });
    builder.addCase(fetchWebsites.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      handleApiResponse(action.payload);
    });
    //UPDATE WEBSITE DATA
    builder.addCase(updateWebsites.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateWebsites.fulfilled, (state, action) => {
      state.loading = false;
      handleApiResponse(action.payload);
    });
    builder.addCase(updateWebsites.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      handleApiResponse(action.payload.response);
    });
    //CREATE WEBSITE DATA
    builder.addCase(createWebsites.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createWebsites.fulfilled, (state, action) => {
      state.loading = false;
      handleApiResponse(action.payload);
    });
    builder.addCase(createWebsites.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      handleApiResponse(action.payload.response);
    });

    //DELETE WEBSITE
    builder.addCase(deleteWebsite.fulfilled, (state, action) => {
      const { websiteId, response } = action.payload;
      let index = state.data.findIndex((e) => e._id === websiteId);
      state.data.splice(index, 1);
      handleApiResponse(response);
    });
    builder.addCase(deleteWebsite.rejected, (state, action) => {
      handleApiResponse(action.payload.response);
    });

    //UPDATE WEBSITE STATUS
    builder.addCase(updateWebsiteStatus.fulfilled, (state, action) => {
      const { websiteId, status, response } = action.payload;
      let index = state.data.findIndex((e) => e._id === websiteId);
      state.data[index].status = status === "Active" ? true : false;
      handleApiResponse(response);
    });
    builder.addCase(updateWebsiteStatus.rejected, (state, action) => {
      handleApiResponse(action.payload.response);
    });
    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const { setFilterVisible, resetFilter, closeFilters, applyFilter } =
  websiteSlice.actions;
export {
  fetchWebsites,
  updateWebsites,
  createWebsites,
  deleteWebsite,
  updateWebsiteStatus,
};

// this is for configureStore
export default websiteSlice.reducer;
