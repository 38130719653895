import { CheckCircleFilled } from "@ant-design/icons";
import template from "../../../assets/import-website-template.xlsx";
import "./Guidelines.scss";
import { useNavigate } from "react-router-dom";

const CheckPoint = ({ title, children }) => {
  return (
    <p className="check-point">
      <CheckCircleFilled />
      <b>{title}</b>&nbsp;
      {children}
    </p>
  );
};

const Guidelines = () => {
  const navigate = useNavigate();

  return (
    <div className="guideline">
      <div className="header">
        <h1>Website Data Import Guidelines</h1>
        <p>
          To avoid errors and ensure successful data import, please adhere to
          the following guidelines when uploading files:
        </p>
      </div>

      <div>
        <CheckPoint title="File Format">
          Ensure that the file to be uploaded is in either Excel (XLSX) or CSV
          format
        </CheckPoint>
        <CheckPoint title="File Template">
          Use the provided file template to structure the data correctly. This
          ensures compatibility with the import process and prevents data
          parsing errors.{" "}
          <a className="link" target="_blank" href={template}>
            Download Template
          </a>
        </CheckPoint>
        <CheckPoint title="Data Validation">
          Validate each field in file before upload to ensure successful import.
          Each attribute must pass the system-defined validation rules to
          continue.
          <span className="link" onClick={() => navigate("validation-guide")}>
            {" "}
            View Validation Guideline
          </span>
        </CheckPoint>
      </div>
      <div className="note">
        <p>
          <b></b> Action of importing data cannot be reversed once executed.
          Please validate the websites after successful import. If you have any
          questions, concerns, or feedback about our services, please feel free
          to <span className="link">contact us.</span>
        </p>
      </div>
    </div>
  );
};

export default Guidelines;
