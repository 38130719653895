import React, { useState, useRef } from "react";
import { Modal, Input, Upload, message } from "antd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import { ARTICLE_STATUS, CONTENT_TYPES } from "../../../../utils/constants";
import { getBase64 } from "../../../../utils/helper";
import sendNotification from "../../../../components/Notification";
import {
  moveInWriting,
  movetoPublished,
  movetoSubmitted,
} from "../../../../store/orderViewReducer";
const { Dragger } = Upload;

const uploadProps = {
  name: "file",
  maxCount: 1,
  multiple: false,
  action: (file) => {
    return false;
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },

  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const WebsiteStatusActions = ({ orderItem }) => {
  const { orderId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const publishedUrl = useRef("");
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleUploadContent = () => {
    if (!file.name || !file.data)
      sendNotification({
        msgType: "error",
        title: "File Missing!",
        message: "Please upload file to continue",
      });
    else {
      dispatch(movetoSubmitted({ orderId, orderItemId: orderItem._id, file }));
      setIsModalOpen(false);
    }
  };

  const handlePublishUrl = () => {
    if (publishedUrl.current.length < 5)
      sendNotification({
        msgType: "error",
        title: "Invalid URL!",
        message: "Please add valid published url to publish this article",
      });
    else {
      dispatch(
        movetoPublished({
          orderId,
          orderItemId: orderItem._id,
          published_url: publishedUrl.current,
        })
      );
      setIsModalOpen(false);
      publishedUrl.current = "";
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (orderItem.status === ARTICLE_STATUS.PENDING)
    return (
      <div className="website-actions">
        {orderItem.content_type === CONTENT_TYPES.CUSTOM_REQUEST ? (
          <p
            className="link"
            onClick={() =>
              dispatch(moveInWriting({ orderId, orderItemId: orderItem._id }))
            }
          >
            In-Writing
          </p>
        ) : (
          <p
            className="link"
            onClick={() =>
              dispatch(movetoSubmitted({ orderId, orderItemId: orderItem._id }))
            }
          >
            Submit
          </p>
        )}
      </div>
    );

  if (orderItem.status === ARTICLE_STATUS.IN_WRITING)
    return (
      <div className="website-actions">
        <p className="link" onClick={showModal}>
          Submit
        </p>
        <Modal
          title="Upload Content"
          open={isModalOpen}
          okText="Submit Article"
          onOk={handleUploadContent}
          onCancel={handleCancel}
        >
          <Dragger
            {...uploadProps}
            customRequest={({ file, data, onSuccess }) => {
              getBase64(file, (binaryData) => {
                setFile({
                  name: file.name,
                  data: binaryData,
                });
              });
              onSuccess();
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Modal>
      </div>
    );

  if (orderItem.status === ARTICLE_STATUS.SUBMITTED)
    return (
      <div className="website-actions">
        <p className="link" onClick={showModal}>
          Publish
        </p>
        <Modal
          title="Add Published URL"
          open={isModalOpen}
          okText="Publish Article"
          onOk={handlePublishUrl}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Input
            defaultValue={publishedUrl.current}
            onChange={(e) => (publishedUrl.current = e.target.value)}
            placeholder="www.hukumat.com/new-article"
          />
        </Modal>
      </div>
    );

  if (orderItem.status === ARTICLE_STATUS.PUBLISHED) {
    return (
      <a href={orderItem.published_url} target="_blank" className="link">
        Visit Link
      </a>
    );
  }
};

export default WebsiteStatusActions;
