import { Table, Timeline, Breadcrumb, Descriptions, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Content from "../../components/Content";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../../store/orderViewReducer";
import WebsiteForm from "../../components/WebsiteForm/WebsiteForm";
import "./AddWebsite.scss";

const AddWebsite = () => {
  useEffect(() => {}, []);

  return (
    <div className="add-website">
      <Breadcrumb
        items={[
          { title: <Link to="/websites">Websites</Link> },
          { title: <span>Create</span> },
        ]}
      />
      <h2 className="heading">Add Website</h2>
      <WebsiteForm />
    </div>
  );
};

export default AddWebsite;
