import handleApiResponse from "../components/ApiResponse";
import authApi from "./authApi";

export async function getWebsiteData(websiteId) {
  try {
    const response = await authApi.get(`website/${websiteId}`);
    if (response.status === 200) return response.data;
  } catch (e) {
    console.log("Error in getWebsiteData", e);
  }
}

export async function getConfig() {
  try {
    const response = await authApi.get(`/config`);
    if (response.status === 200) return response.data;
  } catch (e) {
    console.log("Error in getConfig", e);
  }
}

export async function updatePricing(values) {
  try {
    const response = await authApi.put(`/config/update`, values);
    if (response.status === 200) return response;
  } catch (e) {
    console.log("Error in getConfig", e);
    return e.response;
  }
}

export async function updateBccEmailRecipients(emails) {
  try {
    const response = await authApi.put(`/config/bcc_recipients`, emails);
    if (response.status === 200) return response;
  } catch (e) {
    console.log("Error in updateBccEmailRecipients", e);
    return e.response;
  }
}

export async function getSummary() {
  try {
    const response = await authApi.get(`/analytic/summary`);
    if (response.status === 200) return response.data;
  } catch (e) {
    console.log("Error in getSummary", e);
    return e.response;
  }
}

export async function getOrderStats() {
  try {
    const response = await authApi.get(`/analytic/order-stats`);
    if (response.status === 200) return response.data;
  } catch (e) {
    console.log("Error in getOrderStats", e);
    return e.response;
  }
}

export async function getProductSales() {
  try {
    const response = await authApi.get(`/analytic/product-sales`);
    if (response.status === 200) return response.data;
  } catch (e) {
    console.log("Error in getProductSales", e);
    return e.response;
  }
}
