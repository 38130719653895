import React, { useEffect, useState } from "react";
import Pricing from "./components/Pricing";
import BccEmailRecipient from "./components/BccEmailRecipient";
import { getConfig } from "../../../services/apiCalls";
import "./OrdersConfig.scss";
import Loading from "../../../components/Loading";

const OrdersConfig = () => {
  const [configData, setConfigData] = useState({});
  const [loading, setLoading] = useState(false);
  async function fetchData() {
    const response = await getConfig();
    if (response) setConfigData(response.data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="orders-config">
      <h2 className="title">Orders Config</h2>
      <Pricing configData={configData} />
      <BccEmailRecipient configData={configData} />
    </div>
  );
};

export default OrdersConfig;
