import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { waitFunction } from "../utils/helper";
import { v4 as uuidv4 } from "uuid";
import {
  matchOrderStatus,
  matchStatus,
  searchString,
} from "../utils/validation";
import authApi from "../services/authApi";
import { ORDER_FILTER } from "../utils/constants";
import { revertAll } from "./action";
import handleApiResponse from "../components/ApiResponse";

const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (value, thunkAPI) => {
    try {
      const response = await authApi.get(`order`);
      if (response.status === 200) return response.data.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: "",
  search: "",
  filters: ORDER_FILTER,
  filterResult: [],
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      let ordersList = JSON.parse(JSON.stringify(state.data));
      const { view, search } = action.payload;
      ordersList = ordersList.filter(
        (order) =>
          matchOrderStatus(view, order.status) &&
          searchString(order._id, search)
      );

      state.filterResult = ordersList;
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      handleApiResponse(action.payload);
    });
    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const { applyFilter } = ordersSlice.actions;
export { fetchOrders };

// this is for configureStore
export default ordersSlice.reducer;
