import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getOrderStats } from "../../../services/apiCalls";
import { ORDER_STATUS } from "../../../utils/constants";

ChartJS.register(ArcElement, Tooltip, Legend);
const options = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "right",
    },
  },
};

function OrderStatistics() {
  const [data, setData] = useState([]);

  async function fetchData() {
    const response = await getOrderStats();
    if (response) {
      let orderStats = response.data.reduce((acc, { _id, count }) => {
        acc[_id] = count;
        return acc;
      }, {});
      let tempData = [];
      tempData.push(orderStats.PENDING ?? 0);
      tempData.push(orderStats.IN_PROCESS ?? 0);
      tempData.push(orderStats.COMPLETED ?? 0);
      setData(tempData);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="canvas">
      <Doughnut
        data={{
          labels: ["Pending", "In Process", "Completed"],
          datasets: [
            {
              label: " # of Orders",
              data: data,
              backgroundColor: ["#f16262", "#ffb901", "#52c41a"],
              borderColor: ["#ffff"],
              borderWidth: 2,
              hoverBackgroundColor: ["#cf1322", "#e6a701", "#469a15"],
              offset: 25,
              spacing: 2,
              cutout: 55,
            },
          ],
        }}
        options={options}
        height={200}
      />
    </div>
  );
}

export default OrderStatistics;
