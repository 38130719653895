import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import handleApiResponse from "../components/ApiResponse";
import { revertAll } from "./action";
import authApi from "../services/authApi";
import { sortByTime } from "../utils/helper";

const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async (values, thunkAPI) => {
    try {
      const response = await authApi.get("/notification/1");
      if (response.status === 200) return response.data.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

const loadMore = createAsyncThunk(
  "notification/loadMore",
  async (page, thunkAPI) => {
    try {
      const response = await authApi.get(`/notification/${page}`);
      if (response.status === 200) return response.data.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

const markAllAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (values, thunkAPI) => {
    try {
      const response = await authApi.put("/notification/read-all");
      if (response.status === 200) return response;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

const readNotifiation = createAsyncThunk(
  "notification/read",
  async (notificationId, thunkAPI) => {
    try {
      const response = await authApi.put(
        `/notification/read/${notificationId}`
      );
      if (response.status === 200) return { response, notificationId };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

let initialState = {
  data: [],
  loading: false,
  error: {},
  currentPage: 1,
  disableLoadMore: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //LOGIN USER
    builder.addCase(fetchNotifications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.data = sortByTime(action.payload, "created_at");
      state.currentPage = 1;
      state.disableLoadMore = false;
      state.loading = false;
    });

    builder.addCase(fetchNotifications.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(markAllAsRead.fulfilled, (state, action) => {
      handleApiResponse(action.payload);
      state.data.map((e) => {
        e.isRead = true;
      });
    });
    builder.addCase(loadMore.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadMore.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.data = sortByTime(
          [...state.data, ...action.payload],
          "created_at"
        );
      } else state.disableLoadMore = true;
      state.currentPage++;
      state.loading = false;
    });
    builder.addCase(readNotifiation.fulfilled, (state, action) => {
      const { notificationId } = action.payload;
      let index = state.data.findIndex((e) => e._id === notificationId);
      state.data[index].isRead = true;
    });

    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const {} = notificationSlice.actions;
export { fetchNotifications, markAllAsRead, loadMore, readNotifiation };
// this is for configureStore
export default notificationSlice.reducer;
