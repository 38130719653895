import React, { useEffect, useState } from "react";
import { Button, Space, Form, Input, InputNumber, Checkbox } from "antd";
import { updatePricing } from "../../../../services/apiCalls";
import handleApiResponse from "../../../../components/ApiResponse";

const Pricing = ({ configData }) => {
  const [disableForm, setDisableForm] = useState(true);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const response = await updatePricing(values);
    if (response) handleApiResponse(response);
  };

  useEffect(() => {
    form.setFieldsValue(configData);
  }, [configData]);

  return (
    <div className="pricing">
      <div className="panel-header">
        <h1 className="title">Pricing</h1>
      </div>
      <Checkbox
        checked={disableForm}
        onChange={(e) => setDisableForm(e.target.checked)}
      >
        Disable Form
      </Checkbox>
      <Form
        disabled={disableForm}
        name="pricing"
        form={form}
        requiredMark={false}
        initialValues={{
          remember: true,
        }}
        labelCol={{ span: 3 }}
        className="pricing-form"
        onFinish={onFinish}
      >
        <Form.Item
          label="Content Charges"
          name="content_charges"
          rules={[
            {
              type: "number",
              required: true,
              message: "Please add content charges!",
            },
          ]}
        >
          <InputNumber min={0} max={1000} />
        </Form.Item>
        <Form.Item
          label="Urgent Fee"
          name="urgent_fee"
          rules={[
            {
              type: "number",
              required: true,
              message: "Please add urgent fee!",
            },
          ]}
        >
          <InputNumber min={0} max={1000} />
        </Form.Item>
        <Form.Item
          label="Platform Fee"
          name="platform_fee"
          rules={[
            {
              type: "number",
              required: true,
              message: "Please add platform fee!",
            },
          ]}
        >
          <InputNumber min={0} max={1000} />
        </Form.Item>
        <Form.Item>
          {!disableForm && (
            <Space>
              <Button>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Pricing;
