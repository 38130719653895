import { Col, Row, Layout, Upload, Button, Space, Input, Spin } from "antd";
import Topbar from "../layout/Topbar";
import "./ValidationRules.scss";
import { COUNTRIES } from "../../utils/samples/countries";
import { WEBSITE_CATEGORIES } from "../../utils/samples/categories";

const Field = ({ name, children, example }) => {
  return (
    <Col span={6}>
      <b>
        [{name}] <span className="example">{example}</span>
      </b>
      <ul>{children}</ul>
    </Col>
  );
};

const ValidationRules = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Layout>
      <Topbar showHome={true} />
      <div className="validation-rules">
        <h1 className="title">Field Validation Rules</h1>

        <Row className="fields" gutter={[20, 10]}>
          <Field name="url" example="https://www.example.com">
            <li>Valid URL of the website </li>
            <li>Must begins with the protocol https </li>
            <li>Required: Yes</li>
          </Field>
          <Field name="extension" example=".com">
            <li>Domain extension of the website e.g. .com, .info, .org etc</li>
            <li>Starts with period (.) </li>
            <li>Must be part of the URL </li>
            <li>Required: Yes</li>
          </Field>
          <Field name="country" example="Australia">
            <li>Name of the country where the website is operational</li>
            <li>Value must be string </li>
            <li>
              Use <b>Global</b> for international websites{" "}
            </li>
            <li>Valid countries</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="da" example="19">
            <li>Domain Authority of the website</li>
            <li>Value must be integer</li>
            <li>Value can range from 0-100</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="dr" example="20">
            <li>Domain Rating of the website</li>
            <li>Value must be integer</li>
            <li>Value can range from 0-100</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="traffic" example="2000">
            <li>Monthly Traffic of the website</li>
            <li>Value must be integer</li>
            <li>Value must be greater than or equal to 0</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="links" example="3">
            <li>Number of links allowed in each guest post for this website</li>
            <li>Value must be integer</li>
            <li>Value can range from 0-100</li>
            <li>Required: Yes</li>
          </Field>
          <Field
            name="categories"
            example="General[5,10],Journalism[10,20],Health & Fitness[10,30]"
          >
            <li>List of categories allow for this website</li>
            <li>
              Format: <i>name_of_category[retail_price,sale_price]</i>
            </li>
            <li> Use comma to add multiple categories</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="age" example="2024-01-10">
            <li>Age of the website</li>
            <li>Valid format is YYYY-MM-DD</li>
            <li>Required: Yes</li>
          </Field>
          <Field name="spam" example="10">
            <li>Spam Score</li>
            <li>Value must be integer</li>
            <li>Value can range from 0-100</li> <li>Required: Yes</li>
          </Field>
          <Field name="vendor" example="John Doe">
            <li>Name/Details of the vendor</li>
            <li>Value must be string</li>
            <li>Required: No</li>
          </Field>
          <Field name="status" example="Active">
            <li>
              Current Status of the website, in case of blocked these website
              won't be available for the users
            </li>
            <li>Value must be string </li>
            <li>Values can be Blocked/Active </li>
            <li>Required: Yes</li>
          </Field>
        </Row>

        <div className="countries">
          <h1 className="title">List of Countries</h1>
          <div className="countries">
            {Object.values(COUNTRIES).map((country) => (
              <span key={country}>[{country}] </span>
            ))}
          </div>
        </div>

        <div className="categories">
          <h1 className="title">List of Categories</h1>
          {WEBSITE_CATEGORIES.map((category) => (
            <span key={category}>[{category}] </span>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ValidationRules;
