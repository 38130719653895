import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import WebsiteForm from "../../components/WebsiteForm/WebsiteForm";
import "./EditWebsite.scss";
import { getWebsiteData } from "../../services/apiCalls";
import sendNotification from "../../components/Notification";
import Loading from "../../components/Loading";

const WebsiteView = () => {
  const { websiteId } = useParams();
  const websiteList = useSelector((state) => state.website.data);
  const [website, setWebsite] = useState(undefined);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    const response = await getWebsiteData(websiteId);
    if (response) {
      setWebsite(response.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    let index = websiteList.findIndex((e) => e._id === websiteId);
    console.log("index", index, websiteList);
    if (index !== -1) {
      setWebsite(websiteList[index]);
      setLoading(false);
    } else fetchData();
  }, [websiteId]);

  return (
    <div className="edit-website">
      <Breadcrumb
        items={[
          { title: <Link to="/websites">Websites</Link> },
          { title: <span> Website#{websiteId}</span> },
        ]}
      />

      <h2 className="heading">Edit Website</h2>
      {loading ? <Loading /> : <WebsiteForm defaultValue={website} />}
    </div>
  );
};

export default WebsiteView;
