import { WEBSITE_CATEGORIES } from "../../utils/samples/categories";
import {
  isAgeValid,
  isCountryValid,
  isExtensionValid,
  isScoreValid,
  isStatusValid,
  isTrafficValid,
  isUrlValid,
  parseCategories,
} from "../../utils/validation";

export const generateRule = (fieldName, isRequired = true) => {
  return [
    {
      required: isRequired,
      message: `'${fieldName}' required!`,
    },
  ];
};

function validateCategories(website) {
  let isValid = website.categories.findIndex((category) => {
    const { cost, name, price } = category;
    if (!(Number.isInteger(cost) && cost <= 1000 && cost >= 0)) return true;
    if (!(Number.isInteger(price) && price <= 1000 && price >= 0)) return true;
    if (!WEBSITE_CATEGORIES.includes(name)) return true;
    delete category._id;
  });

  return isValid === -1;
}

export const validateWebsiteData = (values) => {
  return new Promise((resolve, rejected) => {
    let website = { ...values };

    website.age = values.age.format("YYYY-MM-DD");
    website.status = values.status ? "Active" : "Blocked";
    website.url = "https://" + values.url;

    if (!isUrlValid(website.url))
      rejected({
        msgType: "error",
        title: "Invalid Url",
        message: `${website.url} is not  a valid URL`,
      });

    try {
      new URL(website.url);
    } catch (e) {
      rejected({
        msgType: "error",
        title: "Invalid Url",
        message: `${website.url} is not  a valid URL`,
      });
    }

    //validate Extension
    if (!isExtensionValid(website))
      rejected({
        msgType: "error",
        title: "Invalid Extention",
        message: `[${website.extension}] is not a valid extension. Value for this field should start with period (.) and must include in url.`,
      });

    //validate DA
    if (!isScoreValid(website.da))
      rejected({
        msgType: "error",
        title: "Invalid DA",
        message: `[${website.da}] is not a valid score. Value for this field should range in between 0-100.`,
      });

    //validate DR
    if (!isScoreValid(website.dr))
      rejected({
        msgType: "error",
        title: "Invalid DR",
        message: `[${website.dr}] is not a valid score. Value for this field should range in between 0-100.`,
      });

    //validate Spam Score
    if (!isScoreValid(website.spam))
      rejected({
        msgType: "error",
        title: "Invalid Spam Score",
        message: `[${website.spam}] is not a valid score. Value for this field should range in between 0-100.`,
      });

    //validate Links
    if (!isScoreValid(website.links))
      rejected({
        msgType: "error",
        title: "Invalid Links",
        message: `[${website.spam}] is not a valid score. Value for this field should range in between 0-100.`,
      });

    //validate Age
    if (!isAgeValid(website.age))
      rejected({
        msgType: "error",
        title: "Invalid Website Age",
        message: `[${website.age}] is not a valid age. Value for this field should follow the format YYYY-MM-DD.`,
      });

    //validate Traffic
    if (!isTrafficValid(website.traffic))
      rejected({
        msgType: "error",
        title: "Invalid Traffic",
        message: `[${website.traffic}] is not a valid value. Value for this field should range in between 0-Infinity.`,
      });

    //validate Status
    if (!isStatusValid(website))
      rejected({
        msgType: "error",
        title: "Invalid Status",
        message: `[${website.status}] is not a valid status. Value for this field could be either Blocked or Active.`,
      });

    //validate country
    if (!isCountryValid(website))
      rejected({
        msgType: "error",
        title: "Invalid Status",
        message: `[${values.country}] is not a valid country name. Please check available countries in import guideline.`,
      });

    if (!Array.isArray(values.categories) || values.categories.length < 1)
      rejected({
        msgType: "error",
        title: "Invalid Categories",
        message: `Add minimum one category to proceed!.`,
      });

    //validate categories
    if (!validateCategories(website))
      rejected({
        msgType: "error",
        title: "Invalid Categories",
        message: `Website Categories are invalid. The Cost/Price must range from 0-1000 and the name of category must be from the given list.`,
      });

    resolve(website);
  });
};
