import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { revertAll } from "./action";
import handleApiResponse from "../components/ApiResponse";
import authApi from "../services/authApi";
import { ARTICLE_STATUS } from "../utils/constants";
import { sortByTime } from "../utils/helper";

const fetchOrder = createAsyncThunk(
  "orderView/fetchOrder",
  async (orderId, thunkAPI) => {
    try {
      const response = await authApi.get(`order/${orderId}`);
      if (response.status === 200) return response.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const updateOrderStatus = createAsyncThunk(
  "orderView/updateOrderStatus",
  async ({ orderId, orderStatus }, thunkAPI) => {
    try {
      const response = await authApi.put(
        `order/order-status/${orderId}/${orderStatus}`
      );

      if (response.status === 200) return { response, orderStatus };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const moveInWriting = createAsyncThunk(
  "orderView/moveInWriting",
  async ({ orderId, orderItemId }, thunkAPI) => {
    try {
      const response = await authApi.put(
        `order-item/in-writing/${orderId}/${orderItemId}`
      );
      if (response.status === 200) return { response, orderItemId };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const movetoSubmitted = createAsyncThunk(
  "orderView/movetoSubmitted",
  async ({ orderId, orderItemId, file }, thunkAPI) => {
    try {
      const response = await authApi.put(
        `order-item/submitted/${orderId}/${orderItemId}`,
        { file }
      );
      if (response.status === 200) return { response, orderItemId, file };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const movetoPublished = createAsyncThunk(
  "orderView/movetoPublished",
  async ({ orderId, orderItemId, published_url }, thunkAPI) => {
    try {
      const response = await authApi.put(
        `order-item/published/${orderId}/${orderItemId}`,
        { published_url }
      );
      if (response.status === 200)
        return { response, orderItemId, published_url };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  data: {},
  history: [],
  loading: true,
  error: "",
};
export const orderViewSlice = createSlice({
  name: "orderView",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      let orderData = action.payload.data;
      let orderItemHistory = [];
      orderData.items.map((e) => {
        orderItemHistory = [...orderItemHistory, ...e.history];
      });

      state.history = sortByTime(
        [...orderData.history, ...orderItemHistory],
        "time"
      );
      state.data = orderData;
      state.loading = false;
    });
    builder.addCase(fetchOrder.rejected, (state, action) => {
      state.error = action.payload;
      handleApiResponse(action.payload.response);
    });
    builder.addCase(updateOrderStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      const { response, orderStatus } = action.payload;
      state.data.status = orderStatus;
      handleApiResponse(response);
      state.loading = false;
    });
    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.error = action.payload;
      handleApiResponse(action.payload.response);
    });

    builder.addCase(moveInWriting.fulfilled, (state, action) => {
      const { orderItemId, response } = action.payload;
      // const orderData = JSON.parse(JSON.stringify(state.data));
      // const orderData = state.data;
      const { items } = state.data;
      let index = items.findIndex((e) => e._id === orderItemId);
      items[index].status = ARTICLE_STATUS.IN_WRITING;
      // state.data = orderData;
      handleApiResponse(response);
    });
    builder.addCase(moveInWriting.rejected, (state, action) => {
      handleApiResponse(action.payload.response);
    });
    builder.addCase(movetoSubmitted.fulfilled, (state, action) => {
      const { orderItemId, response, file } = action.payload;
      const { items } = state.data;
      let index = items.findIndex((e) => e._id === orderItemId);
      if (items[index].status === ARTICLE_STATUS.IN_WRITING)
        items[index].file = file;
      items[index].status = ARTICLE_STATUS.SUBMITTED;
      handleApiResponse(response);
    });
    builder.addCase(movetoSubmitted.rejected, (state, action) => {
      handleApiResponse(action.payload.response);
    });
    builder.addCase(movetoPublished.fulfilled, (state, action) => {
      const { orderItemId, response, published_url } = action.payload;
      const { items } = state.data;
      let index = items.findIndex((e) => e._id === orderItemId);

      items[index].status = ARTICLE_STATUS.PUBLISHED;
      items[index].published_url = published_url;
      handleApiResponse(response);
    });
    builder.addCase(movetoPublished.rejected, (state, action) => {
      handleApiResponse(action.payload.response);
    });
    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const {} = orderViewSlice.actions;
export {
  fetchOrder,
  updateOrderStatus,
  moveInWriting,
  movetoSubmitted,
  movetoPublished,
};

// this is for configureStore
export default orderViewSlice.reducer;
