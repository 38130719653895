import React, { useEffect, useState } from "react";
import { Space, Table, Tag, Dropdown, Button } from "antd";
import { Input } from "antd";
import {
  SearchOutlined,
  MoreOutlined,
  SmileOutlined,
  DownloadOutlined,
  CheckOutlined,
  StopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  applyAction,
  applyFilter,
  setSelectedRows,
  updateCustomerStatus,
} from "../../../store/customerReducer";
import "./CustomersActions.scss";
import { USER_STATUS } from "../../../utils/constants";
import {
  capitalizeFirstLetter,
  downloadExcel,
  formatDate,
} from "../../../utils/helper";
const CustomersActions = () => {
  const customers = useSelector((state) => state.customers.data);
  const filters = useSelector((state) => state.customers.filters);
  const selectedRows = useSelector((state) => state.customers.selectedRows);
  const filterResult = useSelector((state) => state.customers.filterResult);

  const dispatch = useDispatch();
  const [total, setTotal] = useState({ disabled: 0, all: 0, active: 0 });

  const items = [
    {
      key: USER_STATUS.BLOCKED,
      label: "Block",
      icon: <StopOutlined />,
    },
    {
      key: USER_STATUS.PENDING,
      label: "Pending",
      icon: <SyncOutlined />,
    },
    {
      key: USER_STATUS.ACTIVE,
      label: "Activate",
      icon: <CheckOutlined />,
    },
    {
      key: "Export",
      label: "Export (.xlsx)",
      icon: <DownloadOutlined />,
    },
  ];
  const handleAction = (e) => {
    switch (e.key) {
      case "Export":
        const excelData = selectedRows.map((e) => ({
          "First Name": e.first_name,
          "Last Name": e.last_name,
          Email: e.email,
          Phone: e.phone,
          Country: e.country.name,
          "Last Activity": formatDate(e.last_activity),
          "Total Orders": e.total_orders,
          "Last Order": formatDate(e.last_order),
          "Total Spending": e.total_spending,
          Status: e.status,
        }));
        downloadExcel(excelData, "Customers");
        break;

      case USER_STATUS[e.key]:
        let users = selectedRows.map((i) => i._id);
        dispatch(updateCustomerStatus({ users, status: e.key }));
        break;

      default:
        console.log("Unknown Action Type");
    }
    dispatch(setSelectedRows([]));
  };

  const changeView = (view) => {
    dispatch(applyFilter({ ...filters, view }));
  };

  const ViewType = ({ total, value }) => {
    let isSelected = value === filters.view;
    return (
      <p
        className={isSelected ? "view-active" : "view"}
        onClick={() => changeView(value)}
      >
        {capitalizeFirstLetter(value)} ({total})
      </p>
    );
  };

  useEffect(() => {
    let totalAll = customers.length;
    let totalBlocked = customers.filter(
      (e) => e.status === USER_STATUS.BLOCKED
    ).length;

    let totalPending = customers.filter(
      (e) => e.status === USER_STATUS.PENDING
    ).length;

    let totalActive = customers.filter(
      (e) => e.status === USER_STATUS.ACTIVE
    ).length;
    setTotal({
      pending: totalPending,
      blocked: totalBlocked,
      active: totalActive,
      all: totalAll,
    });
  }, [customers]);

  return (
    <div className="customers-actions">
      <Space className="flex-grow">
        <ViewType value="All" total={total.all} />
        <ViewType value={USER_STATUS.ACTIVE} total={total.active} />
        <ViewType value={USER_STATUS.PENDING} total={total.pending} />
        <ViewType value={USER_STATUS.BLOCKED} total={total.blocked} />
        {filters.search !== "" && (
          <div className="search-result">
            <b>{filterResult.length}</b> results for <b>"{filters.search}"</b>
          </div>
        )}
      </Space>

      <div className={`bulk-action ${selectedRows.length < 1 && "hide"}`}>
        <div className="number">({selectedRows.length}) </div>
        <div className="text"> selected items </div>

        <div id="action-dropdown">
          <Dropdown
            menu={{
              items,
              onClick: handleAction,
            }}
            // getPopupContainer={() => document.getElementById(`action-dropdown`)}
            trigger={["click"]}
          >
            <Button icon={<MoreOutlined />}>Actions</Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default CustomersActions;
