import React from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Redirect,
  Route,
  useHistory,
} from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.auth.authInfo);
  if (!isAuth.token) return <Navigate to="/login" />;

  return children;
};

export default PrivateRoute;
