import React from "react";
import { Col, Row } from "antd";
import CodeInput from "./components/CodeInput";
import Content from "./components/Content";
import "./VerificationCode.scss";
import Logo from "../../components/Logo";

const VerificationCode = () => {
  return (
    <div className="auth-container verification-code">
      <div className="auth-logo">
        <Logo />
      </div>
      <div className="auth-content">
        <Row justify="center">
          <Col xs={24} md={24} lg={12}>
            <CodeInput />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VerificationCode;
