import axios from "axios";

function storeModule() {
  const { store } = require("../store/store.js");
  return store;
}

function revertModule() {
  const { revertAll } = require("../store/action");
  return revertAll;
}

const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

authApi.interceptors.request.use(
  (config) => {
    const token = storeModule()?.getState().auth?.authInfo?.token ?? "";

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("auth api interceptor error", error);
    if (error.response.status === 401) {
      storeModule().dispatch(revertModule()());
      error.response.status = 401;
      return Promise.reject(error.response);
    } else return Promise.reject(error);
  }
);

export default authApi;
