import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
import { getProductSales } from "../../../services/apiCalls";

function generateDateRange() {
  let dates = [];
  let currentDate = moment().subtract(1, "month");
  let endDate = moment();
  while (currentDate <= endDate) {
    dates.push(currentDate.format("YYYY-MM-DD")); // Format as YYYY-MM-DD
    currentDate.add(1, "day");
  }
  return dates;
}

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
let delayed;
const options = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
  },
  scales: {
    x: {
      title: { text: "Total Sales in last month", display: true },
      type: "time",
      time: {
        unit: "day",
      },
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 15,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  },
};

const ProductSales = () => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getProductSales();
    if (response) {
      const dateRange = generateDateRange();
      const dailyCounts = response.data.reduce((acc, { _id, x, y }) => {
        acc[_id] = { x, y };
        return acc;
      }, {});
      let finalResult = [];
      dateRange.forEach((date) =>
        finalResult.push({ x: date, y: dailyCounts[date]?.y ?? 0 })
      );
      setData(finalResult);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="canvas">
      <Line
        options={options}
        data={{
          datasets: [
            {
              fill: true,
              data,
              borderColor: "#ffb901",
              pointBorderWidth: 1,
              loading: true,
              pointRadius: 3,
              pointBorderColor: "#90a4ae",
              pointBackgroundColor: "#ffff",
              backgroundColor: "#ffb90140",
            },
          ],
        }}
        height={200}
        loading={true}
      />
    </div>
  );
};

export default ProductSales;
