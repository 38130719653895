import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TextLog from "../dashboard/ImportWebsites/Logs/TextLog";
import ResultLog from "../dashboard/ImportWebsites/Logs/ResultLog";
import ErrorLog from "../dashboard/ImportWebsites/Logs/ErrorLog";
import { v4 as uuidv4 } from "uuid";
import { waitFunction } from "../utils/helper";
import ActionLog from "../dashboard/ImportWebsites/Logs/ActionLog";
import { revertAll } from "./action";
import authApi from "../services/authApi";

function uploadLogHandling(operation, { status, reason, value }, state) {
  if (status === "fulfilled") {
    if (value)
      state.logs.push(<TextLog key={uuidv4()} text={value.data.message} />);
  } else if (reason?.response?.data)
    state.logs.push(
      <ErrorLog
        key={uuidv4()}
        error={`${operation} OPERATION | ${reason.response.data.message} ${reason.response.data.error}
       `}
      />
    );
  else
    state.logs.push(
      <ErrorLog
        key={uuidv4()}
        error={`${operation} Websites Operation Failed`}
      />
    );
}

const upload = createAsyncThunk(
  "website/upload",
  async ({ createWebsites, updateWebsites }, thunkAPI) => {
    try {
      const createWebsiteResponse =
        createWebsites.length > 0
          ? authApi.post("/website/create", createWebsites)
          : null;
      const updateWebsiteResponse =
        updateWebsites.length > 0
          ? authApi.put("/website/update", updateWebsites)
          : null;
      return Promise.allSettled([createWebsiteResponse, updateWebsiteResponse]);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const initialState = {
  file: undefined,
  logs: [],
  result: { old: [], new: [] },
  loading: false,
  error: "",
};
export const importSlice = createSlice({
  name: "import",
  initialState,
  reducers: {
    addTextLog: (state, action) => {
      let comp = <TextLog key={uuidv4()} text={action.payload} />;
      state.logs.push(comp);
    },
    addErrorLog: (state, action) => {
      let comp = <ErrorLog key={uuidv4()} error={action.payload} />;
      state.logs.push(comp);
    },
    addResultLog: (state, action) => {
      let comp = <ResultLog key={uuidv4()} />;
      state.logs.push(comp);
    },
    addActionLog: (state, action) => {
      let comp = <ActionLog key={uuidv4()} />;
      state.logs.push(comp);
    },
    setFile: (state, action) => {
      state.file = action.payload;
    },
    resetImportData: (state, action) => {
      state.file = undefined;
      state.logs = [];
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upload.pending, (state, action) => {
      state.loading = true;
      state.logs.pop();
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.loading = false;
      const createRes = action.payload[0];
      const updateRes = action.payload[1];

      uploadLogHandling("CREATE", createRes, state);
      uploadLogHandling("UPDATE", updateRes, state);

      if (createRes.status === "fulfilled" && updateRes.status === "fulfilled")
        state.logs.push(
          <TextLog key={uuidv4()} text="File Import Successfully Completed!" />
        );
      else if (
        createRes.status === "fulfilled" ||
        updateRes.status === "fulfilled"
      )
        state.logs.push(
          <TextLog
            key={uuidv4()}
            text="File Import was partially successfull!"
          />
        );
      else
        state.logs.push(
          <ErrorLog
            key={uuidv4()}
            error="File Import Failed! Please try again!"
          />
        );
    });
    builder.addCase(upload.rejected, (state, action) => {
      state.loading = false;
      const { response } = action.payload;
      if (response) {
        state.logs.push(
          <ErrorLog
            key={uuidv4()}
            error={response.data.message + " " + response.data.error}
          />
        );
      } else
        state.logs.push(
          <ErrorLog key={uuidv4()} error="Something went wrong!" />
        );

      state.logs.push(
        <ErrorLog key={uuidv4()} error="Please try again later!" />
      );
    });
    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const {
  addErrorLog,
  addTextLog,
  addActionLog,
  addResultLog,
  setFile,
  setResult,
  resetImportData,
} = importSlice.actions;
export { upload };

// this is for configureStore
export default importSlice.reducer;
