import React, { useEffect, useMemo, useState } from "react";
import { Button, Space, Form, Input } from "antd";
import { updateBccEmailRecipients } from "../../../../services/apiCalls";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import handleApiResponse from "../../../../components/ApiResponse";
import { arraysAreIdentical } from "../../../../utils/helper";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 0,
    },
    sm: {
      span: 0,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const BccEmailRecipient = ({ configData }) => {
  const [form] = Form.useForm();
  const isUpdated = Form.useWatch(
    (values) =>
      !arraysAreIdentical(values.emails, configData?.bcc_email_recipients),
    form
  );

  const onFinish = async (values) => {
    const response = await updateBccEmailRecipients(values.emails);
    if (response) handleApiResponse(response);
  };

  useEffect(() => {
    form.setFieldsValue({ emails: configData.bcc_email_recipients });
  }, [configData]);

  return (
    <div className="bcc-emails">
      <div className="panel-header">
        <h1 className="title">BCC Email Recipients</h1>
        <p>
          Below added emails address will be used as BCC in all order related
          system generated mails.
        </p>
      </div>
      <Form
        form={form}
        name="dynamic_form_item"
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.List name="emails">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item {...formItemLayout} required={false} key={field.key}>
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        whitespace: true,
                        message: "Please enter valid email address.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="i.e. xyz@gmail.com"
                      style={{
                        width: "80%",
                      }}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "80%",
                  }}
                  icon={<PlusOutlined />}
                >
                  Add Email Address
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          {isUpdated && (
            <Space>
              <Button>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default BccEmailRecipient;
