import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { revertAll } from "./action";
import handleApiResponse from "../components/ApiResponse";

const login = createAsyncThunk("auth/login", async (values, thunkAPI) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/auth/login",
      values
    );
    if (response.status === 200) {
      const { token } = response.data.data;
      if (token)
        return thunkAPI.rejectWithValue("You can not perform this action");
      else return thunkAPI.fulfillWithValue(response);
    }
  } catch (err) {
    console.log("err", err);
    return thunkAPI.rejectWithValue(err.response);
  }
});

const verifyOTP = createAsyncThunk(
  "auth/verify-otp",
  async (values, thunkAPI) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/verify-otp",
        values
      );
      if (response.status === 200) {
        const { token } = response.data.data;
        if (jwtDecode(token).role !== "ADMIN")
          return thunkAPI.rejectWithValue("You can not perform this action");
        else return thunkAPI.fulfillWithValue(response);
      }
    } catch (err) {
      console.log("err", err);
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

let initialState = {
  authInfo: {},
  loading: false,
  error: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addAuth: (state, action) => {
      state.authInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    //LOGIN USER
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.authInfo = action.payload.data.data;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log("login rejected");
      state.loading = false;
    });
    //TWO STEP VERIFICATION
    builder.addCase(verifyOTP.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.loading = false;
      state.authInfo = action.payload.data.data;
    });

    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const { addAuth } = authSlice.actions;

export { login, verifyOTP };

// this is for configureStore
export default authSlice.reducer;
