import React from "react";
import { Card, Col, Row, Statistic } from "antd";
import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import Loading from "../../../../components/Loading";
import "./StatsCard.scss";

const StatsCard = ({ title, icon, prefix, data, loading }) => {
  return (
    <Col span={6}>
      <div className="stats-card">
        <div className="header">
          <Statistic
            title={title}
            value={loading ? " " : data?.total ?? "---"}
            prefix={data?.total ? prefix : ""}
            suffix={loading ? <Loading /> : ""}
          />

          <div className="icon">{icon}</div>
        </div>

        <div className="history">
          {data?.percentage > 0 ? <RiseOutlined /> : <FallOutlined />}
          {data?.percentage > 0 ? (
            <p className="grow">{data?.percentage ?? "---"} </p>
          ) : (
            <p className="fall">{data?.percentage ?? "---"}</p>
          )}

          <p className="text">Since last week</p>
        </div>
      </div>
    </Col>
  );
};

export default StatsCard;
