import React, { useState, useRef, useEffect } from "react";
import {
  LockOutlined,
  UserOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {
  Button,
  Switch,
  Checkbox,
  InputNumber,
  Form,
  message,
  Divider,
  DatePicker,
  Input,
  Row,
  Col,
  Select,
  Space,
} from "antd";
import { useNavigate, Link } from "react-router-dom";
import WebsiteCategories from "./WebsiteCategories";
import { COUNTRIES } from "../../utils/samples/countries";
import dayjs from "dayjs";
import WebsiteDetails from "./WebsiteDetails";
import "../styles/WebsiteForm/WebsiteForm.scss";
import moment from "moment";
import { useDispatch } from "react-redux";
import { createWebsites, updateWebsites } from "../../store/websiteReducer";
import { validateValues } from "../../dashboard/ImportWebsites/helper";
import sendNotification from "../Notification";
import { validateWebsiteData } from "./helper";

const FormItem = ({ span, children, ...formValues }) => (
  <Col span={span}>
    <Form.Item {...formValues}>{children}</Form.Item>
  </Col>
);

const WebsiteForm = ({ defaultValue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const website = await validateWebsiteData(values);
      console.log("website", website);
      if (defaultValue) {
        dispatch(updateWebsites([{ _id: defaultValue._id, ...website }]));
      } else dispatch(createWebsites([website]));

      navigate("/websites");
    } catch (e) {
      console.log("Error in website save", e);
      sendNotification(e);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      form.setFieldsValue({
        ...defaultValue,
        country: defaultValue.country.name,
        age: dayjs(defaultValue.age, "YYYY-MM-DD"),
      });
      console.log("form values", form.getFieldsValue());
    }
  }, [defaultValue]);

  return (
    <Form
      name="website-form"
      className="website-form"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      <div className="content">
        <div className="website-details">
          <WebsiteDetails isCreate={defaultValue ? true : false} />{" "}
          <FormItem>
            <Space>
              <Link to="/websites">
                <Button className="website-form-button">Cancel</Button>
              </Link>

              <Button
                type="primary"
                htmlType="submit"
                className="website-form-button"
              >
                Save
              </Button>
            </Space>
          </FormItem>
        </div>

        <WebsiteCategories />
      </div>
    </Form>
  );
};
export default WebsiteForm;
