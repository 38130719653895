import React from "react";
import { Button, Divider, Empty, Spin, Avatar, Badge } from "antd";
import { BellFilled, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import Dropdown from "../../../components/Dropdown";
import Icon from "@ant-design/icons";
import { ReactComponent as ReadIcon } from "../../../assets/read.svg";
import { ReactComponent as BulletPoint } from "../../../assets/circle.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMore,
  markAllAsRead,
  readNotifiation,
} from "../../../store/notificationReducer";
import { Link } from "react-router-dom";
import moment from "moment";
import "../styles/Notification.scss";

const renderNotifications = (notifications, handleReadNotification) => {
  function handleReadNotification(notification) {
    if (!notifications.isRead) handleReadNotification(notification._id);
  }

  return notifications.map((notification) => ({
    key: notification._id,
    label: (
      <Link
        to={notification.path}
        onClick={() => handleReadNotification(notification)}
      >
        {" "}
        <div
          className={`notification-item ${
            notification.isRead ? "read" : "unread"
          }`}
        >
          <div className="main">
            {!notification.isRead && (
              <Icon component={BulletPoint} className="bullet" />
            )}
            <div className="content">
              <p
                className="message"
                dangerouslySetInnerHTML={{
                  __html: notification.message,
                }}
              />

              <span className="date">
                {moment(notification.created_at).format(
                  "MMM DD, YYYY [at] hh:mm A"
                )}
              </span>
            </div>
            <div className="avatar">
              <Avatar size={40} icon={<UserOutlined />} />
            </div>
          </div>
          <Divider />
        </div>
      </Link>
    ),
  }));
};

const EmptyNotification = () => {
  return (
    <div className="notification-wrapper">
      <div className="empty">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    </div>
  );
};

const NotificationsContent = ({ children }) => {
  const dispatch = useDispatch();
  const { currentPage, loading, disableLoadMore } = useSelector(
    (state) => state.notification
  );
  return (
    <div className="notification-wrapper">
      <div className="header">
        <h1>Notifications</h1>
        <div className="mark-read" onClick={() => dispatch(markAllAsRead())}>
          <Icon component={ReadIcon} color="blue" />
          <h3>Mark all as read</h3>
        </div>
      </div>
      {children}
      <div className="notification-action">
        {loading ? (
          <div className="loading">
            <Spin indicator={<LoadingOutlined />} spin="true" /> Loading
          </div>
        ) : !disableLoadMore ? (
          <p
            className="load-more"
            onClick={() => dispatch(loadMore(currentPage + 1))}
          >
            Read more
          </p>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

const Notification = () => {
  const notifications = useSelector((state) => state.notification.data);
  const dispatch = useDispatch();

  const handleReadNotification = (id) => dispatch(readNotifiation(id));

  return (
    <Dropdown
      items={renderNotifications(notifications, handleReadNotification)}
      className="notification"
      DropdownWrapper={
        notifications.length > 0 ? NotificationsContent : EmptyNotification
      }
      onClick={console.log}
      placement="bottomRight"
    >
      <Badge count={notifications.filter((e) => !e.isRead).length}>
        <Button icon={<BellFilled />} />
      </Badge>
    </Dropdown>
  );
};

export default Notification;
