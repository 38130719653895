import { Link } from "react-router-dom";
import logoImg from "../assets/logo.png";
import darkLogoImg from "../assets/logo-white.png";

export default function Logo({ bgDark }) {
  return (
    <Link to="/">
      {bgDark ? <img src={darkLogoImg} /> : <img src={logoImg} />}
    </Link>
  );
}
