import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { matchStatus, searchString } from "../utils/validation";
import { CUSTOMER_FILTER } from "../utils/constants";
import { revertAll } from "./action";
import authApi from "../services/authApi";
import handleApiResponse from "../components/ApiResponse";

const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (value, thunkAPI) => {
    try {
      const response = await authApi.get("/user/all");
      if (response.status === 200) return response.data.data;
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const updateCustomerStatus = createAsyncThunk(
  "customers/updateCustomerStatus",
  async ({ users, status }, thunkAPI) => {
    try {
      const response = await authApi.put(`/user/status/${status}`, users);
      if (response.status === 200) return { response, users, status };
      else return thunkAPI.rejectWithValue(response);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: "",
  filters: CUSTOMER_FILTER,
  filterResult: [],
  selectedRows: [],
};
export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    applyFilter: (state, action) => {
      let customersList = JSON.parse(JSON.stringify(state.data));
      const { view, search } = action.payload;
      customersList = customersList.filter(
        (customer) =>
          matchStatus(view, customer.status) &&
          searchString(customer.first_name + " " + customer.last_name, search)
      );

      state.filterResult = customersList;
      state.filters = action.payload;
    },

    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(updateCustomerStatus.fulfilled, (state, action) => {
      const { response, status, users } = action.payload;
      handleApiResponse(response);

      state.filterResult = state.filterResult.map((e) =>
        users.includes(e._id) ? { ...e, status: status } : e
      );
      state.data = state.data.map((e) =>
        users.includes(e._id) ? { ...e, status: status } : e
      );
    });
    builder.addCase(revertAll, () => initialState);
  },
});

// this is for dispatch
export const { applyFilter, setSelectedRows } = customersSlice.actions;
export { fetchCustomers, updateCustomerStatus };

// this is for configureStore
export default customersSlice.reducer;
