import sendNotification from "./Notification";

const handleApiResponse = (response, title) => {
  if (!response || !response.status || !response.data)
    return sendNotification({
      msgType: "error",
      title: "Something went wrong!",
      message: "Server is not responding. Please try again later",
    });

  if (response.status === 422)
    sendNotification({
      msgType: "error",
      title: response.data.message,
      message: response.data.error,
    });
  else if (response.status === 401)
    sendNotification({
      msgType: "error",
      title: "Unauthorized Request",
      message: "Your session token is expired. Please login to continue",
    });
  else if (response.status === 500)
    sendNotification({
      msgType: "error",
      title: response.data.message,
      message: response.data.error,
    });
  else if ((response.status = 200)) {
    sendNotification({
      msgType: "success",
      title: response.data.message,
    });
  }
};

export default handleApiResponse;
