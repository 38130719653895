import React from "react";
import { Table, Timeline, Breadcrumb, Descriptions, Tag } from "antd";
import { useSelector } from "react-redux";
import "./OrderHistory.scss";
import { formatDate } from "../../../utils/helper";

const OrderHistory = () => {
  const history = useSelector((state) => state.orderView.history);
  return (
    <div>
      <h2 className="heading">Order History</h2>
      <div className="order-history">
        <Timeline
          mode="left"
          items={history.map((history) => ({
            children: history.event,
            label: formatDate(history.time),
          }))}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
