import React, { useState, useEffect } from "react";
import { Col, Row, Layout, Button, Menu } from "antd";
import Topbar from "../layout/Topbar";
import Notification from "./Notification/Notification";
import Account from "./Account/Account";
import OrdersConfig from "./OrderConfig/OrdersConfig";
import "./Settings.scss";

const items = [
  { label: "Orders Config", key: "orders-config" },
  { label: "Account & Security", key: "account" },
  { label: "Notification", key: "notification" },
];

const Settings = () => {
  const [selected, setSelected] = useState("orders-config");

  return (
    <Layout>
      <Topbar showHome={true} />
      <div className="settings">
        <Row>
          <Col span={4}>
            <div className="sidebar">
              <Menu
                className="menu"
                mode="inline"
                defaultSelectedKeys={selected}
                items={items}
                onClick={(item) => {
                  setSelected(item.key);
                }}
              />
            </div>
          </Col>
          <Col span={20}>
            <div className="content">
              {selected === "notification" && <Notification />}
              {selected === "account" && <Account />}
              {selected === "orders-config" && <OrdersConfig />}
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Settings;
