import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import RouterConfig from "./routes/RouterConfig";
import "./App.scss";
import { store, persistor } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterConfig />
      </PersistGate>
    </Provider>
  );
}

export default App;
