import React, { useEffect } from "react";
import { Table } from "antd";
import "./WebsitesList.scss";
import { useSelector, useDispatch } from "react-redux";
import { fetchWebsites } from "../../../store/websiteReducer";
import FilterHeader from "./FilterHeader";
import { WEBSITE_COLUMNS } from "../../../utils/columns";
import handleApiResponse from "../../../components/ApiResponse";
import sendNotification from "../../../components/Notification";

const WebsitesList = () => {
  const websites = useSelector((state) => state.website);
  const { data, filterResult, isApplyFilter, loading } = websites;
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(fetchWebsites())
        .unwrap()
        .then(() => {
          // dispatch(fetchPricing());
        })
        .catch((err) => {
          handleApiResponse(err, "Fetch websites failed!");
        });
    } catch (error) {
      sendNotification({
        msgType: "error",
        title: error.title,
        message: error.message,
      });
    }
  }, []);

  return (
    <div className="website-list">
      <FilterHeader />
      <Table
        rowKey="_id"
        scroll={{ x: 1800 }}
        columns={WEBSITE_COLUMNS}
        dataSource={isApplyFilter ? filterResult : data}
        loading={loading}
        rowClassName={(record) => (!record.status ? "disable-row" : "")}
      />
    </div>
  );
};

export default WebsitesList;
