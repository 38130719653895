import { useEffect, useState } from "react";
import {
  PieChartOutlined,
  UsergroupAddOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import StatsCard from "./StatsCard";
import { getSummary } from "../../../../services/apiCalls";

const Summary = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  async function fetchData() {
    setLoading(true);
    const response = await getSummary();
    if (response) setData(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <StatsCard
        data={data.totalActiveOrders}
        loading={loading}
        title="Active Orders"
        icon={<ShoppingCartOutlined />}
      />
      <StatsCard
        data={data.totalOrders}
        title="Total Orders"
        loading={loading}
        icon={<PieChartOutlined />}
      />
      <StatsCard
        data={data.totalSales}
        loading={loading}
        title="Total Sales"
        icon={<DollarOutlined />}
        prefix="$"
      />
      <StatsCard
        data={data.totalCustomers}
        loading={loading}
        title="Total Customers"
        icon={<UsergroupAddOutlined />}
      />
    </>
  );
};

export default Summary;
