import React from "react";
import BrandingLogo from "../../components/BrandingLogo";
import LoginForm from "./components/Form";
import "./Login.scss";
import Logo from "../../components/Logo";

const Login = () => {
  return (
    <div className="login">
      <div className="container">
        <div className="logo">
          <Logo />
        </div>
        <h1 className="heading">( Admin Panel )</h1>
        <LoginForm />
        <div className="branding">
          <p>DIGITAL MARKETING PARTNER</p>
          <BrandingLogo />
        </div>
      </div>
    </div>
  );
};

export default Login;
