import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, Descriptions, Select } from "antd";
import Content from "../../components/Content";
import { useDispatch, useSelector } from "react-redux";
import OrderHistory from "./components/OrderHistory";
import Loading from "../../components/Loading";
import { ARTICLE_STATUS, ORDER_STATUS } from "../../utils/constants";
import { fetchOrder, updateOrderStatus } from "../../store/orderViewReducer";
import WebsiteStatusTable from "./components/WebsiteStatus/WebsiteStatusTable";
import moment from "moment";

import "./OrderView.scss";
import { formatDate } from "../../utils/helper";

const OrderView = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { data: orderDetails, loading } = useSelector(
    (state) => state.orderView
  );

  useEffect(() => {
    dispatch(fetchOrder(orderId));
  }, [orderId]);

  useEffect(() => {}, [orderDetails]);

  if (loading) return <Loading />;

  if (orderDetails)
    return (
      <div className="order-view">
        <div className="breadcrumb">
          <Breadcrumb
            items={[
              { title: <Link to="/orders">Orders</Link> },
              { title: <span> Order#{orderId}</span> },
            ]}
          />
        </div>

        <Content title="Order Details" loading={loading}>
          <Descriptions
            size="small"
            column={{
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
              xl: 24,
              xxl: 24,
            }}
          >
            <Descriptions.Item label="Order Id" span={18}>
              <b> {orderId}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Customer Email" span={6}>
              <p> {orderDetails.customer.email}</p>
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={18}>
              <Select
                size="small"
                style={{ minWidth: 200 }}
                defaultValue={orderDetails.status}
                onChange={(orderStatus) =>
                  dispatch(updateOrderStatus({ orderId, orderStatus }))
                }
                options={Object.keys(ORDER_STATUS).map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
              {/* <Tag color="geekblue">{orderDetails.status}</Tag> */}
            </Descriptions.Item>
            <Descriptions.Item label="Due date" span={6}>
              {orderDetails.due_at ? formatDate(orderDetails.due_at) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Created at" span={18}>
              {formatDate(orderDetails.created_at)}
            </Descriptions.Item>

            <Descriptions.Item label="Total Amount" span={6}>
              {orderDetails.status === ORDER_STATUS.DRAFT ? (
                <p></p>
              ) : (
                <p> ${orderDetails.total_order_amount}</p>
              )}
            </Descriptions.Item>
          </Descriptions>

          <div className="articles-list">
            <WebsiteStatusTable
              label="Pending"
              data={orderDetails.items.filter(
                (e) => e.status === ARTICLE_STATUS.PENDING
              )}
            />
            <WebsiteStatusTable
              label="In Writing"
              data={orderDetails.items.filter(
                (e) => e.status === ARTICLE_STATUS.IN_WRITING
              )}
            />
            <WebsiteStatusTable
              label="Submitted"
              data={orderDetails.items.filter(
                (e) => e.status === ARTICLE_STATUS.SUBMITTED
              )}
            />
            <WebsiteStatusTable
              label="Published"
              data={orderDetails.items.filter(
                (e) => e.status === ARTICLE_STATUS.PUBLISHED
              )}
            />
          </div>
          <OrderHistory />
        </Content>
      </div>
    );
};

export default OrderView;
