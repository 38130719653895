import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ORDER_ITEM_STATUS_COLUMN } from "../../../../utils/columns.js";
import "./WebsiteStatus.scss";

const WebsiteStatusTable = ({ data, label }) => {
  const loading = useSelector((state) => state.orderView.loading);
  const [filterResults, setFilterResults] = useState([]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setFilterResults(
      data.filter((e) => e.website.url.includes(value.toLowerCase()))
    );
  };

  useEffect(() => {
    setFilterResults(data);
  }, [data]);
  return (
    <div className="website-status">
      <div className="header">
        <h1 className="heading">{label}</h1>
        <Input
          allowClear
          placeholder="Search website"
          onChange={handleSearch}
          prefix={<SearchOutlined />}
        />
      </div>
      <div className="content">
        <Table
          rowKey="_id"
          columns={ORDER_ITEM_STATUS_COLUMN}
          dataSource={filterResults}
          pagination={{ pageSize: 5 }}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default WebsiteStatusTable;
