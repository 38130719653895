import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import DashboardLayout from "../dashboard/layout/Layout";
import Login from "../pages/Login/Login";
import Websites from "../dashboard/Websites/Websites";
import Orders from "../dashboard/Orders/Orders";
import OrderView from "../dashboard/OrderView/OrderView";
import Settings from "../dashboard/Settings/Settings";
import Customers from "../dashboard/Customers/Customers";
import Email from "../dashboard/Email/Email";
import EditWebsite from "../dashboard/EditWebsite/EditWebsite";
import AddWebsite from "../dashboard/AddWebsite/AddWebsite";
import ImportWebsites from "../dashboard/ImportWebsites/ImportWebsites";
import Dashboard from "../dashboard/Dashboard/Dashboard";
import ValidationRules from "../dashboard/ValidationRules/ValidationRules";
import VerificationCode from "../pages/VerificationCode/VerificationCode";

const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<VerificationCode />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        >
          <Route path="customers" element={<Customers />} />
          <Route path="websites" element={<Websites />} />
          <Route path="websites/:websiteId" element={<EditWebsite />} />
          <Route path="websites/new" element={<AddWebsite />} />
          <Route path="email" element={<Email />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:orderId" element={<OrderView />} />
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/import"
          element={
            <PrivateRoute>
              <ImportWebsites />
            </PrivateRoute>
          }
        />
        <Route
          path="/import/validation-guide"
          element={
            <PrivateRoute>
              <ValidationRules />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterConfig;
