import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate, Link } from "react-router-dom";
import handleApiResponse from "../../../components/ApiResponse";
import { validateSignin } from "../../../utils/validation";
import sendNotification from "../../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/authReducer";

const LoginForm = () => {
  const loading = useSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      let validatedValues = await validateSignin(values);
      dispatch(login(validatedValues))
        .unwrap()
        .then((response) => {
          console.log("response", response);
          handleApiResponse(response);
          navigate(`/verify`);
        })
        .catch((err) => {
          handleApiResponse(err);
        });
    } catch (error) {
      sendNotification({
        msgType: "error",
        title: error.title,
        message: error.message,
      });
    }
  };
  return (
    <Form
      name="normal_login"
      layout="vertical"
      requiredMark={false}
      className="login-form"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your Username!",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          Login
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
