import React, { useState } from "react";
import {
  DeleteOutlined,
  CheckOutlined,
  StopOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Space, Button, Dropdown, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteWebsite,
  updateWebsiteStatus,
} from "../../../store/websiteReducer";

const WebsiteActions = ({ record }) => {
  const navigate = useNavigate();
  const cart = useSelector((state) => state.website.cart);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const items = [
    {
      key: "Edit",
      label: (
        <Link to={`/websites/${record._id}`}>
          <span className="menu-item-option">
            <EditOutlined /> Edit
          </span>
        </Link>
      ),
    },
    {
      key: "Block",
      label: (
        <Popconfirm
          placement="left"
          title={
            <p>
              Are you sure to block this website{" "}
              <span className="link">{record.url}</span>?
            </p>
          }
          onConfirm={() =>
            dispatch(
              updateWebsiteStatus({ websiteId: record._id, status: "Blocked" })
            )
          }
          okText="Yes"
          cancelText="No"
        >
          <span className="menu-item-option">
            <StopOutlined /> Block
          </span>{" "}
        </Popconfirm>
      ),
    },
    {
      key: "Activate",
      label: (
        <Popconfirm
          placement="left"
          title={
            <p>
              Are you sure to the Activate this website{" "}
              <span className="link">{record.url}</span>?
            </p>
          }
          onConfirm={() =>
            dispatch(
              updateWebsiteStatus({ websiteId: record._id, status: "Active" })
            )
          }
          okText="Yes"
          cancelText="No"
        >
          <span className="menu-item-option">
            <CheckOutlined /> Activate
          </span>{" "}
        </Popconfirm>
      ),
    },
    {
      key: "Delete",
      label: (
        <Popconfirm
          placement="left"
          title={
            <p>
              Are you sure to the delete this website{" "}
              <span className="link">{record.url}</span>?
            </p>
          }
          onConfirm={() => dispatch(deleteWebsite(record._id))}
          okText="Yes"
          cancelText="No"
        >
          <span className="menu-item-option">
            <DeleteOutlined /> Delete
          </span>
        </Popconfirm>
      ),
    },
  ];

  const renderItems = (record) => {
    if (record.status) return items.filter((item) => item.key !== "Activate");
    return items.filter((item) => item.key !== "Block");
  };

  return (
    <div className="website-actions">
      <div id="action-dropdown">
        <Dropdown
          onClick={() => setOpen(!open)}
          open={open}
          arrow={true}
          menu={{
            items: renderItems(record),
            onClick: () => setOpen(false),
          }}
          placement="bottom"
          getPopupContainer={() => document.getElementById(`action-dropdown`)}
          trigger={["click"]}
        >
          <MoreOutlined />
        </Dropdown>
      </div>
    </div>
  );
};

export default WebsiteActions;
