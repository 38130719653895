import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import sendNotification from "../../../components/Notification";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function CountdownTimer() {
  const navigate = useNavigate("");
  const authInfo = useSelector((state) => state.auth.authInfo);
  const [remainingTime, setRemainingTime] = useState(
    moment(authInfo.code_last_updated)
      .add(5, "minutes")
      .diff(moment(), "second")
  ); // Initial remaining time in seconds

  useEffect(() => {
    setRemainingTime(
      moment(authInfo.code_last_updated)
        .add(5, "minutes")
        .diff(moment(), "second")
    );
  }, [authInfo]);

  useEffect(() => {
    const timer = setInterval(() => {
      // Decrease the remaining time by 1 second
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
    }, 1000); // Update the time every 1000ms (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  // Convert remainingTime to minutes and seconds
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  if (seconds > 0)
    return (
      <span>
        Time Left: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    );
  else
    return (
      <a className="link" onClick={() => navigate("/login")}>
        Session Expired! Please Login again!
      </a>
    );
}

export default CountdownTimer;
