import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import authReducer from "./authReducer";
import websiteReducer from "./websiteReducer";
import ordersReducer from "./ordersReducer";
import orderViewReducer from "./orderViewReducer";
import customerReducer from "./customerReducer";
import importReducer from "./importReducer";
import notificationReducer from "./notificationReducer";

const authPersistConfig = {
  key: "auth",
  storage,
};

const notificationPersistConfig = {
  key: "notification",
  storage,
};

const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    website: websiteReducer,
    orders: ordersReducer,
    orderView: orderViewReducer,
    customers: customerReducer,
    importWebsites: importReducer,
    notification: persistReducer(
      notificationPersistConfig,
      notificationReducer
    ),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
