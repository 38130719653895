import React, { useMemo, useState } from "react";
import BrandingLogo from "../../../components/BrandingLogo";
import VerificationInput from "react-verification-input";
import { Button } from "antd";
import CountdownTimer from "./CountdownTimer";
import { LockFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { verifyAccount, verifyOTP } from "../../../store/authReducer";
import sendNotification from "../../../components/Notification";
import { useNavigate } from "react-router-dom";
import handleApiResponse from "../../../components/ApiResponse";

const CodeInput = () => {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authInfo, loading } = useSelector((state) => state.auth);

  const handleVerifyAccount = () => {
    try {
      dispatch(
        verifyOTP({
          code: parseInt(code),
          token: authInfo.verification_token,
          email: authInfo.email,
        })
      )
        .unwrap()
        .then((response) => {
          handleApiResponse(response);
          navigate("/");
        })
        .catch((err) => {
          handleApiResponse(err, "Verification Failed!");
        });
    } catch (error) {
      console.log(error);
      sendNotification({
        msgType: "error",
        title: error.title,
        message: error.message,
      });
    }
  };

  return (
    <div className="form-wrapper-container">
      <div className="form-wrapper-content">
        <LockFilled className="lock-icon" />
        <h1 className="auth-heading">Two-Step Verification</h1>
        <p className="auth-paragraph">
          Please enter the OTP (one time password) to verify your account. A
          code has been sent to your email!
        </p>
        <div className="verification-code-wrapper">
          <VerificationInput
            placeholder="_"
            onChange={(e) => setCode(e)}
            validChars="0-9"
            inputProps={{ inputMode: "numeric" }}
            length={6}
            classNames={{
              container: "container",
              character: "character",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
          />
        </div>
        <Button
          type="primary"
          className="verify-btn"
          loading={loading}
          disabled={code.length < 6}
          onClick={handleVerifyAccount}
        >
          Confirm
        </Button>
        <div className="countdown">
          <CountdownTimer />
        </div>
        <div className="branding">
          <BrandingLogo />
        </div>
      </div>
    </div>
  );
};

export default CodeInput;
